import { useFormAction, useNavigation } from '@remix-run/react'
import { clsx, type ClassValue } from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSpinDelay } from 'spin-delay'
import { extendTailwindMerge } from 'tailwind-merge'
import { extendedTheme } from './extended-theme'

export function getUserImgSrc(imageId?: string | null) {
	return imageId ? `/resources/user-images/${imageId}` : '/img/user.png'
}

export function getNoteImgSrc(imageId: string) {
	return `/resources/note-images/${imageId}`
}

export function getOrganizationLogoSrc(logoId?: string | null) {
	if (!logoId) {
		return '/img/user.png'
	}
	return `/resources/organization-images/${logoId}`
}

export function getErrorMessage(error: unknown) {
	if (typeof error === 'string') return error
	if (
		error &&
		typeof error === 'object' &&
		'message' in error &&
		typeof error.message === 'string'
	) {
		return error.message
	}
	console.error('Unable to get error message for error', error)
	return 'Unknown Error'
}

function formatColors() {
	const colors = []
	for (const [key, color] of Object.entries(extendedTheme.colors)) {
		if (typeof color === 'string') {
			colors.push(key)
		} else {
			const colorGroup = Object.keys(color).map(subKey =>
				subKey === 'DEFAULT' ? '' : subKey,
			)
			colors.push({ [key]: colorGroup })
		}
	}
	return colors
}

const customTwMerge = extendTailwindMerge<string, string>({
	extend: {
		theme: {
			colors: formatColors(),
			borderRadius: Object.keys(extendedTheme.borderRadius),
		},
		classGroups: {
			'font-size': [
				{
					text: Object.keys(extendedTheme.fontSize),
				},
			],
			animate: [
				{
					animate: Object.keys(extendedTheme.animation),
				},
			],
		},
	},
})

export function cn(...inputs: ClassValue[]) {
	return customTwMerge(clsx(inputs))
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get('X-Forwarded-Host') ??
		request.headers.get('host') ??
		new URL(request.url).host
	const protocol = host.includes('localhost') ? 'http' : 'https'
	return `${protocol}://${host}`
}

export function getReferrerRoute(request: Request) {
	// spelling errors and whatever makes this annoyingly inconsistent
	// in my own testing, `referer` returned the right value, but 🤷‍♂️
	const referrer =
		request.headers.get('referer') ??
		request.headers.get('referrer') ??
		request.referrer
	const domain = getDomainUrl(request)
	if (referrer?.startsWith(domain)) {
		return referrer.slice(domain.length)
	} else {
		return '/'
	}
}

/**
 * Merge multiple headers objects into one (uses set so headers are overridden)
 */
export function mergeHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const merged = new Headers()
	for (const header of headers) {
		if (!header) continue
		for (const [key, value] of new Headers(header).entries()) {
			merged.set(key, value)
		}
	}
	return merged
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const combined = new Headers()
	for (const header of headers) {
		if (!header) continue
		for (const [key, value] of new Headers(header).entries()) {
			combined.append(key, value)
		}
	}
	return combined
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
	...responseInits: Array<ResponseInit | null | undefined>
) {
	let combined: ResponseInit = {}
	for (const responseInit of responseInits) {
		combined = {
			...responseInit,
			headers: combineHeaders(combined.headers, responseInit?.headers),
		}
	}
	return combined
}

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
export function invariant(
	condition: any,
	message: string | (() => string),
): asserts condition {
	if (!condition) {
		throw new Error(typeof message === 'function' ? message() : message)
	}
}

/**
 * Provide a condition and if that condition is falsey, this throws a 400
 * Response with the given message.
 *
 * inspired by invariant from 'tiny-invariant'
 *
 * @example
 * invariantResponse(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Response} if condition is falsey
 */
export function invariantResponse(
	condition: any,
	message: string | (() => string),
	responseInit?: ResponseInit,
): asserts condition {
	if (!condition) {
		throw new Response(typeof message === 'function' ? message() : message, {
			status: 400,
			...responseInit,
		})
	}
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
	formAction,
	formMethod = 'POST',
	state = 'non-idle',
}: {
	formAction?: string
	formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
	state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
	const contextualFormAction = useFormAction()
	const navigation = useNavigation()
	const isPendingState =
		state === 'non-idle'
			? navigation.state !== 'idle'
			: navigation.state === state
	return (
		isPendingState &&
		navigation.formAction === (formAction ?? contextualFormAction) &&
		navigation.formMethod === formMethod
	)
}

/**
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsPending
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsPending({
	formAction,
	formMethod,
	delay = 400,
	minDuration = 300,
}: Parameters<typeof useIsPending>[0] &
	Parameters<typeof useSpinDelay>[1] = {}) {
	const isPending = useIsPending({ formAction, formMethod })
	const delayedIsPending = useSpinDelay(isPending, {
		delay,
		minDuration,
	})
	return delayedIsPending
}

function callAll<Args extends Array<unknown>>(
	...fns: Array<((...args: Args) => unknown) | undefined>
) {
	return (...args: Args) => fns.forEach(fn => fn?.(...args))
}

/**
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
	const [doubleCheck, setDoubleCheck] = useState(false)

	function getButtonProps(
		props?: React.ButtonHTMLAttributes<HTMLButtonElement>,
	) {
		const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>['onBlur'] =
			() => setDoubleCheck(false)

		const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'] =
			doubleCheck
				? undefined
				: e => {
						e.preventDefault()
						setDoubleCheck(true)
				  }

		const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>['onKeyUp'] =
			e => {
				if (e.key === 'Escape') {
					setDoubleCheck(false)
				}
			}

		return {
			...props,
			onBlur: callAll(onBlur, props?.onBlur),
			onClick: callAll(onClick, props?.onClick),
			onKeyUp: callAll(onKeyUp, props?.onKeyUp),
		}
	}

	return { doubleCheck, getButtonProps }
}

/**
 * Simple debounce implementation
 */
function debounce<Callback extends (...args: Parameters<Callback>) => void>(
	fn: Callback,
	delay: number,
) {
	let timer: ReturnType<typeof setTimeout> | null = null
	return (...args: Parameters<Callback>) => {
		if (timer) clearTimeout(timer)
		timer = setTimeout(() => {
			fn(...args)
		}, delay)
	}
}

/**
 * Debounce a callback function
 */
export function useDebounce<
	Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
	const callbackRef = useRef(callback)
	useEffect(() => {
		callbackRef.current = callback
	})
	return useMemo(
		() =>
			debounce(
				(...args: Parameters<Callback>) => callbackRef.current(...args),
				delay,
			),
		[delay],
	)
}

export async function downloadFile(url: string, retries: number = 0) {
	const MAX_RETRIES = 3
	try {
		const response = await fetch(url)
		if (!response.ok) {
			throw new Error(`Failed to fetch image with status ${response.status}`)
		}
		const contentType = response.headers.get('content-type') ?? 'image/jpg'
		const blob = Buffer.from(await response.arrayBuffer())
		return { contentType, blob }
	} catch (e) {
		if (retries > MAX_RETRIES) throw e
		return downloadFile(url, retries + 1)
	}
}

type WithID<T> = T & { id?:  string };
type RequireID<T> = T & { id:  string };


export function objectDiffer<T>(objects: WithID<T>[], ids?: string[] | null) {
    let toCreate: T[] = [];
    let toUpdate: RequireID<T>[] = [];
    let toDelete: string[] = [];

	toCreate = objects.filter(obj => {
		return !obj?.id
	});

	toUpdate = objects.filter(obj => {
		return obj?.id && ids?.includes(obj.id)
	}).map(obj => {
		const {id, ...newObj} = obj;
		return {
			id: obj.id,
			...newObj
		}
	}) as RequireID<T>[];

	const updateIds = [...toUpdate].map(nonDeleteObj => nonDeleteObj.id);
	const toDeleteIds = ids?.filter(dbId => !updateIds?.includes(dbId)) ?? [];

	return {
		toCreate,
		toUpdate,
		toDelete: toDeleteIds
	}
}

export function normalizeText(text: string): string {
    return text
      .toLowerCase()
      .normalize('NFD') // Decompor acentos
      .replace(/[\u0300-\u036f]/g, ''); // Remover acentos
  }

export function searchInObjects<T extends Record<string, any>>(
  array: T[] | undefined,
  searchText?: string,
  keys?: keyof T | (keyof T)[] // Type-safe: Aceita apenas chaves válidas do objeto T
): T[] | undefined {
  if (!array) {
    return undefined;
  }

  if (!searchText) {
    return array;
  }

  const normalizedSearchText = normalizeText(searchText);

  // Função recursiva para pesquisar em todos os níveis do objeto
  const deepSearch = (obj: Record<string, any>, keys?: any): boolean => {
    // Se `keys` for definido, pesquisar apenas nesses atributos
    const attributes = keys ? (Array.isArray(keys) ? keys : [keys]) : Object.keys(obj);

    return attributes.some(attr => {
      const value = obj[attr];

      if (typeof value === 'string') {
        return normalizeText(value).includes(normalizedSearchText);
      } else if (typeof value === 'object' && value !== null) {
        return deepSearch(value, keys); // Pesquisa recursiva em objetos aninhados
      }
      return false;
    });
  };

  return array.filter(item => deepSearch(item, keys));
}

export function sortByAttribute<T>(
	array: T[] | undefined,
	attribute: keyof T, // Garante que apenas chaves válidas de T sejam aceitas
	order: 'asc' | 'desc' = 'asc' // Define a ordem de classificação
  ): T[] | undefined {
	if (!array || array.length === 0) {
	  return array;
	}
  
	return [...array].sort((a, b) => {
	  const valueA = a[attribute];
	  const valueB = b[attribute];
  
	  if (valueA === valueB) return 0;
  
	  if (order === 'asc') {
		return valueA! > valueB! ? 1 : -1;
	  } else {
		return valueA! < valueB! ? 1 : -1;
	  }
	});
  }

type NestedKeyOf<T> = {
	// @ts-expect-error 
	[K in keyof T]: T[K] extends object ? `${K}.${NestedKeyOf<T[K]>}` : K;
}[keyof T];
  
export  function arrayToMapByKey<T>(array: T[], key: NestedKeyOf<T>): Map<any, T> {
	function getNestedValue<T>(obj: T, path: NestedKeyOf<T>): any {
		// @ts-expect-error
		return path.split('.').reduce((acc, part) => acc && acc[part], obj);
	}
	
	return new Map(array.map(item => [getNestedValue(item, key), item]));
}
  
// Helper function to get the nested value safely

export function obfuscateString(
	value: string,
	startVisible: number = 0,
	endVisible: number = 0,
	maskChar: string = '*'
  ): string {
	if (value.length <= startVisible + endVisible) {
	  return value; // If string length is less than visible chars, return original value
	}
  
	const visibleStart = value.slice(0, startVisible);
	const maskedPart = value.slice(startVisible, -endVisible).replace(/./g, maskChar);
	const visibleEnd = value.slice(-endVisible);
  
	return visibleStart + maskedPart + visibleEnd;
  }

  export const mapToObject = <K extends string, V>(map: Map<K, V>): Record<K, V> => {
	return Object.fromEntries(map) as Record<K, V>;
  };


  export const randomUUID = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0;
		var v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
};
